body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
}

.react-calendar {
    border: unset !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.rowSelected {
    background-color: #a9fcdf !important;
}

.rowSelected:hover,
.ui.selectable.table tbody tr.rowSelected:hover {
    background-color: #d7fff1 !important;
}

span {
    overflow: hidden;
    text-overflow: ellipsis;
}

.react-view:before,
.react-view:after {
    display: none !important;
}

.fullWidthDatePicker,
.fullWidthDatePicker div.field,
.fullWidthDatePicker div.input,
.fullWidthDatePicker input {
    width: 100%;
    text-align: center !important;
}

.ui.statistic > .value.smallStatistic {
    font-size: 2.5rem !important;
}

.ui.statistic > .value.smallestStatistic {
    font-size: 2rem !important;
}

.rightTextField > div.input > input {
    text-align: right;
}

.noScrollBars {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.noScrollBars::-webkit-scrollbar {
    display: none;
}

.sidebarContainer {
    max-width: 120px !important;
}

.only-print,
only-print * {
    display: none !important;
}

.chat-item > img {
    height: 50px !important;
    width: 50px !important;
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }

    .only-print,
    only-print * {
        display: inherit !important;
    }
}

div.rc-slider-track.rc-slider-track-1 {
    background-color: #55aa61;
}

span.rc-slider-dot.rc-slider-dot-active {
    border-color: #55aa61;
}

div.rc-slider-handle,
div.rc-slider-handle.rc-slider-handle-click-focused {
    border-color: #55aa61;
}

.ui.menu:not(.vertical) .left.item.noRightMargin,
.ui.menu:not(.vertical) :not(.dropdown) > .left.menu {
    /* display: flex; */
    margin-right: 0px !important;
}

.react-grid-item {
    margin: 0px !important;
}

.droppableContainer > div {
    width: 100%;
    height: 150%;
}

.ui.form > .some-margin > .text.no-margin {
    display: none;
}

/* 
.dashboardHeader .ui.statistic {
	margin: 0px !important;
} */

.leaflet-control-attribution {
    display: none;
}

.location-picker > .leaflet-container {
    height: 600px !important;
}